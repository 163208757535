<template>
  <section class="form">
    <v-form class="the_form_wrapper" @submit.prevent="submit" v-model="valid">
      <p class="form_head">
        Admin Login
      </p>
      <v-text-field
          label="Email"
          v-model="form_data.email"
          filled
          rounded
          dense
          background-color="#fff"
          append-icon="mdi-email"
          reverse
          :rules="emailRules"
      ></v-text-field>

      <!-- email -->
      <v-text-field
          v-model="form_data.password"
          label="Password"
          filled
          rounded
          dense
          background-color="#fff"
          append-icon="mdi-password"
          reverse
          type="password"
      ></v-text-field>

      <div class="the_button">
        <button class="form">
          <span v-if="!loading_submit">
            Login
          </span>
          <v-progress-circular
              v-else
              size="24"
              color="info"
              indeterminate
          ></v-progress-circular>
        </button>
        <p class="red" v-if="error">{{error}}</p>
      </div>
    </v-form>
  </section>
</template>

<script>
import {admin_login} from "@/api/general";

export default {
  data: () => ({
    pressedRegister: false,
    loading_submit: false,
    loading: false,
    error: '',
    valid: false,
    form_data: {
      email: null,
      password: null,
    },
    emailRules: [
      v => !!v || 'برجاء ادخال البريد الالكتروني',
      v => /.+@.+/.test(v) || 'برجاء ادخال البريد الالكتروني',
    ],
  }),
  methods: {
    submit() {
      if(this.valid) {
        this.loading_submit = true;
        admin_login(this.form_data).then(() => {
          this.loading_submit = false;
          this.$router.push('/page/admin')
        }).catch(err => {
          this.error = err.data.msg
          this.loading_submit = false;
        });
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
section.form {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>